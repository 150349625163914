<const/closeWindow = () => {
  const parent = window.opener || window.parent;
  parent.postMessage(['close'], '*');
} />

<header class="flex items-center justify-center min-h-24 bg-zinc-800 text-slate-200 z-10">
  <button class="absolute top-2 right-2 aspect-square cursor-pointer text-lg px-2 py-1 rounded-sm hover:bg-zinc-700"
    onClick() { closeWindow(); }
  >&times;</button>
  <p>Questions? Chat with us!</p>
</header>